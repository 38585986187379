/**
 * サンプルクラス
 * @constructor
 */
class NavMenu {
  constructor() {

  }
  /**
   * スマホのナビメニュー
   */
  init() {
    $(function() {
      $('.js-navMenu').on('click',function(){
        $('.l-header').toggleClass('is-open');
      });
      $('.l-header__navMenu a[href]').on('click', function() {
        $('.l-header').removeClass('is-open');
      });
  });
  }
};

export default NavMenu;
