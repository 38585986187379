/**
 * サンプルクラス
 * @constructor
 */
class Modal {
    constructor() {

    }
    /**
     * サンプル
     */
    init() {
        let scrollPosition,
            modalNum;
        $(".p-top__movieTopThum").on("click", function () {
            scrollPosition = $(window).scrollTop();
            $('body').addClass('isFixed').css({ 'top': -scrollPosition });
            modalNum = $(this).attr('id');
            $('.'+modalNum).addClass('isActive');
            $('.'+ modalNum +  ' iframe').attr('src', 'https://www.youtube.com/embed/Kj16DX2h5iQ');
        });
        $(".p-top__movieBotThum").on("click", function () {
            scrollPosition = $(window).scrollTop();
            $('body').addClass('isFixed').css({ 'top': -scrollPosition });
            modalNum = $(this).attr('id');
            $('.'+modalNum).addClass('isActive');
            $('.'+ modalNum +  ' iframe').attr('src', 'https://www.youtube.com/embed/xA-fveX3tPg');
        });
        $(".m-modal__close, .m-modal").on("click", function() {
            $('body').removeClass('isFixed').css({'top': 0});
            window.scrollTo( 0 , scrollPosition );
            $('.'+modalNum).removeClass('isActive');
            $('.'+ modalNum +  ' iframe').attr('src', '');
        });

    }
};

export default Modal;
