import uaNum from "./uaJudgment.js";

/**
 * コモンクラス
 * @constructor
 */
class GoToTopBtn {
    constructor() {

    }
    /**
     * コモン
     */
    init() {
        if (uaNum == 1) { //SPだった場合
            $(window).on("scroll", function () {
                let mainH = $('.l-main').height(),
                    snsH = $('.p-top__sns').height(),
                    contH = (mainH+55)+(snsH+72),
                    h = $(window).height(),
                    st = $(this).scrollTop();
                
                // console.log(contH - h);
                // console.log(st);

                // console.log(st);
                if (st > (contH - (h-10))) { // footer近くになったら発動
                    $('#j-gototop').addClass('isStop');
                } else {
                    $('#j-gototop').removeClass('isStop');
                }

                if (st > 100) { // 100px以上スクロールしたら出てくる
                    $('#j-gototop').fadeIn();
                } else {
                    $('#j-gototop').fadeOut();
                }
            })
        } else {
            $(window).on("scroll", function () {
                let st = $(this).scrollTop();
                // console.log(st);
                if (st > 100) { // 100px以上スクロールしたら出てくる
                    $('#j-gototop').fadeIn();
                } else {
                    $('#j-gototop').fadeOut();
                }
            })
        }

    }

}

export default GoToTopBtn;
