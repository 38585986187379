import Vue from 'vue';

/**
 * サンプルクラス
 * @constructor
 */
class ModalVue {
    constructor() {

    }
    /**
     * サンプル
     */
    init() {

        let app = new Vue({
            el:'#app',
            data(){
                return {
                    bodyFix: false,
                    isShow: false,
                    moviePath: '',
                    isShow2: false,
                    moviePath2: '',
                    scrollY: '',
                    currentY: '',
                    top: '0'
                }
            },
            created() {
                window.addEventListener("scroll", this.handleScroll);
            },
            methods: {
                smooth() {
                    // window.scrollTo(0, 2000)
                },
                clickOpen() {
                    // this.bodyFix = !this.bodyFix,
                    // this.top = '-' + this.scrollY + 'px',
                    // this.isShow = !this.isShow,
                    // this.moviePath = 'https://www.youtube.com/embed/AHrbH69UZeQ',
                    // this.currentY = this.scrollY,
                    // console.log(this.scrollY);
                },
                clickClose() {
                    // this.bodyFix = !this.bodyFix,
                    // this.top = 0,
                    // this.moviePath = '',
                    // this.isShow = !this.isShow,
                    // console.log(this.currentY);
                },
                clickOpen2() {
                    // this.bodyFix = !this.bodyFix,
                    // this.top = '-' + this.scrollY + 'px',
                    // this.isShow2 = !this.isShow2,
                    // this.moviePath2 = 'https://www.youtube.com/embed/YJO1osQwbSI'
                },
                clickClose2() {
                    // this.bodyFix = !this.bodyFix,
                    // this.top = 0,
                    // this.moviePath2 = '',
                    // this.isShow2 = !this.isShow2
                },
                handleScroll() {
                    this.scrollY = window.scrollY;
                    // console.log(this.scrollY);
                },
            },
            // watch: {
            //     top: {
            //         handler() {
            //             if (this.top === 0) {
            //                 console.log('0です');
            //                 window.scrollTo(0, 2000);
            //             } else {
            //                 console.log('変更されました');
            //             }
            //         },
            //         deep: true
            //     }
            // }
        })
    }
};

export default ModalVue;
