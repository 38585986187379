import SimpleBar from 'simplebar';
// import 'simplebar/dist/simplebar.css';

/**
 * サンプルクラス
 * @constructor
 */
class SimpleBars {
  constructor() {

  }
  /**
   * スマホのナビメニュー
   */
  init() {
    new SimpleBar(document.getElementById('js-ssongScroll'), {
      autoHide: false
    });
  }
};

export default SimpleBars;
